$separator-color-light: #313131;
$separator-color: #424242;
$background-color: #1b1a19;
$foreground-color: #242322;
$input-background: #232223;

$dark-btn-background: #8d8d8d;
$light-btn-background: #e4e4e4;
$button-text-color: #d0d0d0;

$theme-color-1: #ad7140;
$theme-color-2: #aa4f43;
$theme-color-3: #c4a688;
$theme-color-4: #916948;
$theme-color-5: #856f5a;
$theme-color-6: #6e5e59;

$primary-color: #8f8f8f;
$secondary-color: #707070;
$muted-color: #696969;

$gradient-color-1 : #c27d45;
$gradient-color-2 : #ad6e3a;
$gradient-color-3 : #af7240;

$lp-bg-color-1 : #421200;
$lp-bg-color-2 : #691903;
$lp-bg-color-3 : #883306;
$lp-bg-color-4 : #b54c12;

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 2 6 10 14 19;
$shadowOpacitiesTop: 0.1 0.3 0.6 0.7 0.8;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.1 0.3 0.6 0.7 0.8;

$logoPath: "../../../assets/img/eTrappLogoDesignFINAL.svg";
$logoPathMobile: "../../../assets/img/eTrappLogoDesignFINAL.svg";

$lpLogoPath: "../../../assets/img/eTrappLogoDesignFINAL.svg";
$lpLogoPathPinned: "../../../assets/img/eTrappLogoDesignFINAL.svg";


@import "../_mixins.scss";
@import "../_vien.style.scss";
